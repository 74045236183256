<template>
  <a :href="item && item.link">
    <div class="cont">
      <img class="over" :src="item && item.image" />
      <div class="overlay">
        <div class="text">
          <h3>{{item && item.title}}</h3>
          <h4>{{item && item.description}}</h4>
          <h5>{{item && item.price}}</h5>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
}
</script>

<style scoped>
.cont {
  position: relative;
}

.over {
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.cont:hover .overlay {
  opacity: 1;
  background: #000000;
  opacity: 0.7;
  cursor: pointer;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.text h3 {
  font-family: "Abel";
  font-style: italic;
  font-weight: normal;
  font-size: 35px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}
.text h4 {
  font-style: italic;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
  text-align: center;

  color: #ffffff;
}
.text h5 {
  font-style: italic;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

@media (max-width: 768px) {
  .overlay {
    display: none;
  }
}
</style>
